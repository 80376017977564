import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "@utils/layout"
import SEO from "@utils/seo"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import CardProof from "@molecules/cards/proof"
import SectionMarkets from "@organisms/sections/markets"

const Proof = ({ data, pageContext }) => {
  const testimonials = data.body.nodes

  return (
    <Layout>
      <SEO title="Proof" />
      <div data-datocms-noindex>
        <Section xlTop lg>
          <Container containerMedium>
            <Heading html="h1" level="h1" center>
              See what a few of our <Underline>5,000+</Underline> customers are
              saying about us.
            </Heading>
          </Container>
        </Section>
        <Section light mdTop lg>
          <Container>
            <Flex>
              {testimonials.map((testimonial, index) => (
                <ColumnProof cards key={index}>
                  <CardProof proof={testimonial} key={index} />
                </ColumnProof>
              ))}
            </Flex>
          </Container>
        </Section>
        <SectionMarkets />
      </div>
    </Layout>
  )
}

export default Proof

const ColumnProof = styled(Column)`
  padding-bottom: var(--spacingGutter);
`

const Underline = styled.span`
  position: relative;

  &:before {
    background-color: var(--aqua--00);
    bottom: -0.2rem;
    content: "";
    height: 50%;
    left: -0.5rem;
    position: absolute;
    width: calc(100% + 1rem);
    z-index: var(--zLost);
  }
`

export const fetchProof = graphql`
  query fetchProof {
    body: allDatoCmsProof(
      sort: { fields: position, order: ASC }
      filter: { meta: { status: { in: ["published", "updated"] } } }
    ) {
      nodes {
        videoUrl
        excerpt
        company
        industry
        customImage {
          alt
          title
          gatsbyImageData(
            width: 480
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
        post {
          date
          slug
          featuredImage {
            alt
            title
            gatsbyImageData(
              width: 480
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        intervieweeName
      }
    }
  }
`
