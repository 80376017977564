import React from "react"
import styled from "styled-components"

import Link from "@atoms/link"
import { Heading } from "@atoms/typography"

const Card = ({ color, ...props }) => {
  return (
    <Link {...props} to={`/payment-solutions/${props.market.slug}`}>
      <Heading html="" level="ssh" noMargin center>
        {props.market.title}
      </Heading>
    </Link>
  )
}

const CardMarket = styled(Card)`
  background-color: ${props =>
    props.color === "white" ? "var(--white)" : "var(--gray--00)"};
  align-items: center;
  border-radius: var(--borderRadius);
  display: flex;
  justify-content: center;
  padding: var(--spacing--xxs);

  &:hover {
    box-shadow: 0 8px 40px 0 rgba(210, 212, 227, 0.2);
    transform: translateY(-0.5rem);
  }
`

export default CardMarket
