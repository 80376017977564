import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import CardMarket from "@molecules/cards/market"
import { media } from "@styles/breakpoints"

const Markets = ({ light, ...props }) => {
  const marketsDrawer = useRef()
  const [drawer, setDrawer] = useState(false)
  const toggleDrawer = () => setDrawer(!drawer)

  const data = useStaticQuery(graphql`
    query fetchMarkets {
      allDatoCmsMarket(sort: {fields: position, order: ASC}){
        markets: nodes {
          title
          slug
        }
      }
    }
  `)

  const { markets } = data.allDatoCmsMarket
  return (
    <Section {...props} lg lgTop>
      <Container sm>
        <Flex>
          <Column>
            <Heading html="h6" level="sh" strong lightGray center>
              Business Types
            </Heading>
            <Heading html="h1" level="h1" center>
              MoonClerk works for many
              <br /> types of businesses
            </Heading>
          </Column>
        </Flex>
      </Container>
      <AccordianContainer>
        <Accordian ref={marketsDrawer} active={drawer === true ? true : false}>
          {markets.map((market, index) => {
            return (
              <ColumnMarket key={index}>
                <CardMarket
                  color={light === true ? "white" : "light"}
                  market={market}
                />
              </ColumnMarket>
            )
          })}
        </Accordian>
        <Toggle onClick={toggleDrawer} onKeyDown={toggleDrawer}>
          <Heading html="h6" level="sh" strong aqua center>
            {drawer === true ? "View Less" : "View All"}
          </Heading>
        </Toggle>
      </AccordianContainer>
    </Section>
  )
}

const SectionMarkets = styled(Markets)`
  background-color: ${props =>
    props.light ? "var(--gray--00)" : "var(--white)"};
`

export default SectionMarkets

const AccordianContainer = styled(Container)`
  text-align: center;
`

const Accordian = styled(Flex)`
  padding-top: 0.5rem;
  margin-bottom: var(--spacing--sm);
  max-height: ${props => (props.active ? "80rem" : "20rem")};
  overflow: hidden;
  transition: var(--transitionBase);

  ${media.sm`
    max-height: ${props => (props.active ? "80rem" : "8rem")};
  `}
  
  ${media.md`
    max-height: ${props => (props.active ? "80rem" : "8rem")};
  `}
  
  ${media.lg`
    max-height: ${props => (props.active ? "80rem" : "10rem")};
  `}
`

const ColumnMarket = styled(Column)`
  margin-bottom: var(--spacing--xxs);

  ${media.xs`
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
    `}
    
    ${media.sm`
    flex-basis: 25%;
    max-width: 25%;
    width: 25%;
  `}

`

const Toggle = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  outline-color: var(--colorPrimary);
  padding: 0.5rem 1rem;

  h6 {
    margin: 0;
  }
`
