import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Link from "@atoms/link"
import { Heading, Paragraph } from "@atoms/typography"
import Card from "@molecules/cards/card"

const CardProof = ({ proof }) => {
  return (
    <Card>
      <ProofLink to={`/${proof.post.slug}`}>
        <Image>
          {proof.customImage ? (
            <GatsbyImage
              image={proof.customImage.gatsbyImageData}
              alt={proof.customImage.alt}
            />
          ) : (
            <GatsbyImage
              image={proof.post.featuredImage.gatsbyImageData}
              alt={proof.post.featuredImage.alt}
            />
          )}
        </Image>
        <Content>
          <Seperator />
          <Heading html="h3" level="h4">
            {proof.company}
          </Heading>
          <Paragraph level="sm">{proof.excerpt}</Paragraph>
          <Info>
            <Heading html="h6" level="sh" lightGray>
              -&nbsp;{proof.intervieweeName}
            </Heading>
            <HeadingIndustry html="h6" level="sh" lightGray>
              &nbsp;|&nbsp;{proof.industry}
            </HeadingIndustry>
          </Info>

          <Heading html="h5" level="sh" strong aqua noMargin>
            Learn More
          </Heading>
        </Content>
      </ProofLink>
    </Card>
  )
}

export default CardProof

const Image = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((3 / 4) * 100%);
  }

  .gatsby-image-wrapper {
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Seperator = styled.div`
  border-bottom: 4px solid var(--colorPrimary);
  left: 0;
  position: absolute;
  top: 0;
  transform: scaleX(0.25);
  transform-origin: left top;
  transition: var(--transitionBase);
  width: 100%;
`

const HeadingIndustry = styled(Heading)`
  text-transform: uppercase;
`

const Content = styled.div`
  padding: 3rem var(--spacing--sm);
  position: relative;
`

const Info = styled.div`
  padding: 1.25rem 0;
  h6 {
    display: inline-block;
  }
`

const ProofLink = styled(Link)`
  &:hover {
    ${Seperator} {
      transform: scaleX(1);
    }
  }
`
